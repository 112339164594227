@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;700;900&family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400&display=swap');
.allSub {
  width: 100%;
  height: 846px;
  background-image: url('../asset/frank.png');
  background-size: cover;
  object-fit: cover;
  background-repeat: no-repeat;
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.5);
}

.subcontainer {
  padding-left: 3.5rem;
  padding-top: 5rem;
}

.sub--text {
  color: #09ad0e;
}
.sub-text {
  max-width: 400px;
  color: #ffffff;
  font-weight: bold;
}
.dotapp {
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: #ae1ec7;
  justify-content: center;
  width: 200px;
  padding: 0rem 2rem;
  border-radius: 48px;
  margin-top: 3rem;
}
.sub_img {
  width: 20px;
  height: 24px;
}
.sub---text {
  font-size: 18px;
  line-height: 32px;
  max-width: 450px;
  color: #ffffff;
  font-family: 'Open Sans';
}
.sub__txt {
  color: #ffffff;
  font-weight: 700;
  font-size: 18px;
  text-align: center;
  margin-top: 150px;
  font-family: 'Open Sans';
}

.sub-text {
  font-size: 3rem;
}

@media screen and (max-width: 1000px) {
  .sub-text {
    font-size: 35px;
  }
  .subcontainer {
    padding-left: 1rem;
    padding-top: 7rem;
  }
  .sub---text {
    font-size: 1.2rem;
    line-height: 32px;
    width: 100%;
    padding: 1rem 0;
    color: #ffffff;
  }
  .allSub {
    background-position-x: 40%;
  }

  .sub__txt {
    font-size: 14px;
  }
}
