@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;700;900&family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400&display=swap');
.heart {
  width: 100%;
  height: 100%;
}
/* .heart_container {
  margin-top: 2rem;
} */

.heart_become {
  margin-left: 15rem;
  font-size: 96px;
  color: #b625b1;
  transform: matrix(1, -0.08, 0.08, 1, 0, 0);
}
.heart_cont {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  width: 50%;
  height: 111px;
  margin: auto;
  border-radius: 30px;
  transform: matrix(1, -0.08, 0.08, 1, 0, 0);
}

.heart_cont img {
  width: 132px;
}

.heart_cont p {
  font-size: 50px;
  color: white;
}

.heart_sub h1 {
  font-size: 56px;
}

.heart_sub {
  text-align: end;
  padding: 0rem 20rem;
  transform: matrix(1, -0.08, 0.08, 1, 0, 0);
}

.heart_text {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 2rem;
}
.heart_sub h1 {
  font-size: 96px;
}
.all_heart {
  background-color: #000000;
  padding: 2rem 0rem;
  border-radius: 30px;
}
.heart_first1 {
  margin: 1.5rem 1.5rem;
}
.heart__first ul li {
  font-size: 1rem;
  line-height: 24px;
}
.heart_first1 > li {
  margin: 1.5rem 0;
  font-size: 18px;
}
.hearty_2 ul li {
  margin: 0.5rem 1.5rem;
  font-size: 1rem;
}
.heart__first {
  background: conic-gradient(
    from 180deg at 50% 50%,
    #ae1ec7 0deg,
    #c71e5b 114.38deg,
    #ae1ec7 360deg
  );
  height: 470px;
  border-radius: 48px;
  width: 25rem;
}

.heart__first p {
  color: white;
  margin: 2rem 1.5rem;
  font-size: 18px;
  text-align: center;
}
.heart__first h1 {
  color: white;
  text-align: center;
}
.heart_cir {
  width: 300px;
  height: 350px;
  background: radial-gradient(
    69.72% 86.45% at 120.43% 67.12%,
    #fb0e0e 0%,
    rgba(231, 23, 23, 0) 100%
  );
  transform: rotate(-9.55deg);
  border-radius: 50%;
  position: relative;
  right: 10rem;
}
.heart_second {
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.heart_cir2 {
  width: 300px;
  height: 400px;
  background: radial-gradient(
    93.02% 93.02% at 15.06% -2.49%,
    #b513ee 0%,
    rgba(255, 252, 251, 0) 100%
  );
  transform: rotate(-9.55deg);
  border-radius: 50%;
  position: relative;
  left: 10rem;
}
.heart_h3 {
  background-color: #000000;
  padding: 0.1rem 2rem;
  border-radius: 40px;
  width: 35%;
  margin: auto;
  box-shadow: 1px 1px 1.2rem rgb(104, 104, 104);
}

.heart_h3 h3 {
  color: #ffffff;
  text-align: center;
}
.heart_sub2 h1 {
  font-size: 40px;
  width: 500px;
  padding: 0 2rem;
}
.heart_sub2 img {
  animation: zoom-in-zoom-out 1s ease infinite;
}

@keyframes zoom-in-zoom-out {
  00% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.2, 1.2);
  }
  100% {
    transform: scale(1, 1);
  }
}
.heart_sub2 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-top: 8rem;
}
/* cards */
.heart2_all .heart-card2 {
  width: 400px;
  height: 320px;
  background: conic-gradient(
    from 180deg at 50% 50%,
    #ae1ec7 0deg,
    #c71e5b 114.38deg,
    #ae1ec7 360deg
  );
  box-shadow: -50px 20px #000000;
  border-radius: 48px;

  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.heart1 {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 5rem 0rem;
}
.heart2 {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.hearty {
  margin-top: 3rem;
}

.heart3 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 3rem;
}
.heart__3 {
  margin: 5rem 2rem;
  display: flex;
}
.heart__3 h1 {
  margin-left: 10%;
  font-size: 4.5rem;
}
.heart__3 img {
  margin-top: 5.5rem;
}

@media screen and (max-width: 1000px) {
  .heart__3 img {
    width: 20%;
    object-fit: contain;
    margin-top: 1.5rem;
  }
  .heart__3 {
    margin: 5rem 1rem;
  }
  .heart__3 h1 {
    font-size: 1.2rem;
  }
  .heart_container {
    margin: 0rem 1rem;
  }
  .heart_sub {
    padding: 0rem;
  }
  /* .heart_sub h1 {
    font-size: 3rem;
  } */
}
.heart_launch {
  display: flex;
  justify-content: center;
  gap: 5rem;
  align-items: center;
}
.heart_launch ul li {
  color: #000000;
  width: 426px;
  font-family: 'Open Sans';
  line-height: 32px;
  font-size: 1.2rem;
}
.heart_launch h1 {
  text-align: center;
  font-size: 50px;
}
.heart_launch .heart_launchimg {
  background: conic-gradient(
    from 180deg at 50% 50%,
    #ae1ec7 0deg,
    #c71e5b 114.38deg,
    #ae1ec7 360deg
  );
  padding: 5rem 3rem;
  border-radius: 48px;
}
.heart_launch img {
  width: 252px;
}
a {
  text-decoration: none;
}

@media screen and (max-width: 1000px) {
  .heart_launch {
    display: block;
  }
  .heart_launch ul li {
    width: 100%;
    line-height: 32px;
    font-size: 16px;
  }
  .heart_launch .heart_launchimg {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3rem 0rem;
    border-radius: 16px;
  }
  .heart_launch {
    padding: 0rem;
  }
  .heart_launch h1 {
    text-align: center;
    font-size: 30px;
  }
}

.heart_user {
  display: flex;
  justify-content: center;
  gap: 3rem;
  align-items: center;
  margin-top: 3rem;
}
.heart_user li {
  color: #000000;
  width: 411px;
  font-size: 1.2rem;
  line-height: 32px;
}
.heart_user h1 {
  text-align: center;
  font-size: 50px;
}
.user_img {
  padding: 5rem 3rem;
  border-radius: 48px;
  background: conic-gradient(
    from 180deg at 50% 50%,
    #ae1ec7 0deg,
    #c71e5b 114.38deg,
    #ae1ec7 360deg
  );
}
.user_img img {
  width: 245px;
}

@media screen and (max-width: 1000px) {
  .heart_user {
    display: flex;
    flex-direction: column-reverse;
  }
  .heart_user li {
    width: 100%;
    font-size: 16px;
  }
  .heart_user {
    padding: 0rem;
  }
  .user_img {
    padding: 3rem 1rem;
    border-radius: 16px;
  }
  .heart_user h1 {
    text-align: center;
    font-size: 30px;
  }
}

@media screen and (max-width: 1000px) {
  .heart_text {
    display: block;
  }
  .heart_become {
    margin-left: 0rem;
    font-size: 3rem;
    margin-top: 5rem;
    text-align: center;
  }
  .heart_cont img {
    width: 50px;
  }

  .heart_cont p {
    font-size: 20px;
    color: white;
  }
  .heart_cont {
    width: 90%;
    height: 70px;
    padding: 1rem 1rem;
  }
  .heart__first {
    width: 100%;
  }
  .heart__first1 {
    margin: 0;
  }

  .heart__first ul li {
    font-size: 16px;
  }
  .heart_first1 > li {
    margin: 0.5rem 0;
    font-size: 16px;
  }
  .heart__first p {
    color: white;
    margin: 1rem 0.5rem;
    font-size: 16px;
    text-align: center;
  }
  .heart__first h1 {
    padding-top: 1rem;
  }
  .all_heart {
    padding: 1rem 0;
  }
  .hearty_2 ul li {
    margin: 0.5rem 0rem;
    font-size: 1rem;
  }
  .heart_sub h1 {
    font-size: 2rem;
  }
  ul li {
    width: 100%;
    font-size: 16px;
  }
  .heart__first p {
    padding: 0 1rem;
  }
  .heart_cir2 {
    display: none;
  }
  .heart_cir {
    display: none;
  }
  .heart_sub2 img {
    width: 10%;
  }
  .heart_sub2 h1 {
    width: 100%;
    font-size: 1rem;
    text-align: center;
    padding: 0rem;
  }
  .heart_h3 h3 {
    width: 100%;
    font-size: 1rem;
  }
  .heart_h3 {
    width: 50%;
  }
  .heart2_all .heart-card2 {
    width: 35%;
    height: 120px;
    border-radius: 10px;
    padding: 0.5rem;
  }
  .heart-card2 h1 {
    font-size: 16px;
  }
  .heart2_all .heart-card2 {
    box-shadow: -15px 10px #000000;
  }
}

.apply {
  text-align: center;
  background-color: #000000;
  width: 350px;
  padding: 0.3rem 1rem;
  margin: 5rem auto;
  border-radius: 40px;
  box-shadow: 1px 1px 1.2rem rgb(104, 104, 104);
}
.apply:hover {
  box-shadow: 1px 1px 1.5rem white;
}
.apply h4 {
  color: #ffffff;
}
@media screen and (max-width: 1000px) {
  .apply {
    width: 235px;
  }
  .apply h4 {
    font-size: 12px;
  }
}
/* .discount {
  background: radial-gradient(
    44.47% 61.1% at 74.08% 58.51%,
    #8d838e 0%,
    rgba(233, 248, 235, 0) 52.06%
  );
  height: 180px;
  z-index: -1;
} */
