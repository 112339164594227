.explore {
  width: 100%;
  height: 100vh;
  margin-top: 5rem;
}

.explore_video {
  width: 100%;
  height: 100%;
  object-fit: cover;

  position: absolute;
}
.explore_content {
  position: relative;
  display: flex;
  flex-direction: column;
  top: 30%;
  margin: 0 10rem;
  align-items: flex-end;
}

.explore-doc {
  display: flex;
  align-items: center;
  background-color: #ae1ec7;
  width: 300px;
  padding: 0 1rem;
  border-radius: 40px;
  box-shadow: 1px 1px 1.2rem white;
  justify-content: center;
  text-align: center;
}

.explore_text {
  width: 400px;
  line-height: 32px;
  text-align: center;
  padding: 1rem 0;
  font-size: 24px;
}
.app_tex {
  padding-left: 1rem;
  color: #ffffff;
  font-weight: 700;
  font-size: 27px;
}

.explore-doc:hover {
  box-shadow: 1px 1px 1.2rem rgb(82, 81, 81);
}
.explore_h1 {
  font-size: 3rem;
}

@media screen and (max-width: 1000px) {
  .explore_content {
    position: none;
    width: 100%;
    top: 0;
    margin: 0;
    display: block;
    align-items: flex-start;
  }
  .explore {
    width: 100%;
    height: 100%;
    margin-top: 3rem;
  }
  .follow_text {
    margin-top: 2rem;
  }
  /* .explore_video {
    object-fit: contain;
    height: auto;
    position: relative;
  } */

  .explore_video {
    display: none;
  }

  .explore_text {
    width: 90%;
    line-height: 32px;

    font-size: 18px;
    padding: 0rem 1rem;
  }
  .explore_h1 {
    text-align: center;
    font-size: 2rem;
  }
  .app_tex {
    font-size: 16px;
    padding-left: 0rem;
  }
  .explore-doc {
    width: 200px;
    margin: auto;
  }
}
