.appbar {
  background-color: #ffffff;
  padding: 0 3rem;
}
.nav {
  color: black;
  text-decoration: none;
  font-weight: 700;
}
@media screen and (max-width: 960px) {
  .appbar {
    padding: 0 0rem;
  }
  .MuiDrawer-paper {
    /* width: 65% !important; */
    /* height: 50px; */
  }
  .nav {
    padding-top: 1rem;
    display: grid;
    font-weight: bold;
  }
  .logos {
    width: 30px;
    height: 38px;
  }
}
