@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;700;900&family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400&display=swap');
.footer {
  width: 100%;
  height: 100%;
  background-color: #1a1c20;
  padding: 4rem 0rem;
}
.footer .footer__container {
  max-width: 1440px;
  margin: 0rem 5rem;
}
.footer__container img {
  width: 200px;
  height: 150px;
  object-fit: contain;
}

.icons_3 {
  padding: 0 0.5rem;
  color: #1da1f2;
}
.icons_2 {
  color: #0072b1;
}
.icons_1 {
  color: #bc2a8d;
  padding: 0rem 0.5rem;
}
.footer h3 {
  font-weight: 700;
  color: #fff;
  line-height: 0;
}
.footer__2 {
  color: #fff;
  font-weight: 700;
}
.footer p {
  color: #fff;
  width: 70%;
}

@media screen and (max-width: 1000px) {
  .footer .footer__container {
    max-width: 1440px;
    margin: 0rem 1rem;
  }
  .footer {
    margin-top: 2rem;
    padding: 3rem 0rem;
  }
  .footer p {
    width: 100%;
    font-size: 10px;
  }
  .footer__container img {
    width: 100px;
    height: 70px;
    object-fit: contain;
  }
}
