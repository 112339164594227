@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;700;900&family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400&display=swap');
.allcontainer {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
}

.content-1 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5rem;
}

.Rect {
  background: linear-gradient(200deg, #c71e5b 30%, #ae1ec7);
  border-radius: 48px;
}
.Rect-1 {
  width: 320px;
}
.po-1 {
  width: 100%;
}
.header {
  font-size: 3rem;
}
.student-header {
  width: 35%;
  text-align: center;
}

.student_text {
  font-size: 1.2rem;

  line-height: 32px;
  font-family: 'Open Sans';
  font-weight: 600px;
}

@media screen and (max-width: 1000px) {
  .content-1 {
    display: block;
  }
  .po-1 {
    display: none;
  }
  .allcontainer .container {
    padding: 0rem 2rem;
  }

  .Rect-1 {
    object-fit: contain;
    width: 90%;
  }

  .header {
    font-size: 2rem;
  }
  .student_text {
    font-size: 16px;
  }

  .student-header {
    width: 100%;
  }

  .Rect {
    border-radius: 18px;
  }
}
