.text_logo {
  width: 100%;
  height: 100%;
}
.text_text {
  text-align: center;
  font-size: 2rem;
  font-weight: 700;
  font-style: italic;
}

.text_text span {
  color: #b625b1;
}
@media screen and (max-width: 1000px) {
  .text_text {
    font-size: 25px;
    font-style: italic;
    line-height: 0;
  }
}
