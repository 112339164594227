@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;700;900&family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400&display=swap');
.aboutculture {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
}
.aboutculture_container {
  margin-top: 5rem;
  /* max-width: 1444px; */
}

.culture__img {
  position: absolute;
  top: 250px;
}
.cul_img {
  width: 280px;
}
.values {
  text-align: center;
  background-color: #ae1ec7;
  padding: 1rem 0;
  margin-top: 10rem;
}

.culture__text {
  position: relative;
  left: 25%;
  top: 2rem;
}
.culture__text {
  width: 45%;
  font-family: 'Open Sans';
  line-height: 26px;
}
.mobile {
  position: absolute;
  left: 75%;
  top: 1rem;
  display: none;
}

@media screen and (max-width: 1000px) {
  .culture__text {
    width: 90%;
    left: 0;
    padding: 0 1rem;
  }
  .culture__img {
    display: none;
  }
  .mobile {
    display: block;
  }
  .cult_mobile {
    width: 100px;
  }
}
