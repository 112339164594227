@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;700;900&family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400&display=swap');
.approach {
  width: 100%;
  height: 100%;
  background-color: white;
}
.approach_container {
  max-width: 1440px;
}
.approach_content {
  margin-top: 5rem;
}
.approach_text {
  padding: 0 20%;
  width: 45%;
}

.approach_img {
  display: flex;
  align-items: center;
  object-fit: contain;
  margin-top: 5rem;
}
.app {
  padding-left: 10px;
}
.header_approach {
  padding-bottom: 2rem;
}
.appdoc_doc {
  padding: 0rem 7rem;
}

.approach .foot {
  display: flex;

  justify-content: space-between;
  align-items: center;
  background-color: #ae1ec7;
  display: flex;
  justify-content: center;
  padding: 2rem 2rem;
  border-radius: 2rem;
}
.approach .footer_image {
  width: 50%;
  height: 50%;
}
.app_text {
  padding-left: 1rem;
  color: #ae1ec7;
}
.app__doc {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  width: 100px;
  padding: 0 2.5rem;
  border-radius: 40px;
}
.foot .app__text {
  color: #ffffff;
}
.appdoc_doc {
  padding: 0rem 1rem;
}
.approach p {
  font-family: 'Open Sans';
  line-height: 26px;
}
.foot h1 {
  color: #ffffff;
}
@media screen and (max-width: 1000px) {
  .approach_text {
    width: 100%;
    padding: 0 3%;
  }
  .approach.header_approach {
    width: 20px;
    font-size: 15px;
  }
  .approach .foot {
    display: block;
    width: 90%;
    background-color: #ae1ec7;

    padding: 2rem 1rem;
    border-radius: 2rem;
  }
  .foot h1 {
    color: #ffffff;
    font-size: 30px;
  }
  .approach .footer_image {
    width: 100%;
    height: 100%;
  }
  .app__doc {
    margin-top: 3rem;
    text-align: center;
  }
  .app__text {
    width: 85%;
  }
  .approach .foot {
    margin-top: 4rem;
  }
}
