.team {
  width: 100%;
  height: 100%;
  background-color: white;
}

.team .team_container {
  max-width: '1440px';
}
.team .team_content {
  padding: 3rem 4rem;
}
.team__grids {
  display: flex;
  justify-content: center;
}
.team h1 {
  margin-left: 320px;
}
.team_grid {
  width: 15rem;
  height: 15rem;
  border: 1px solid rgb(223, 221, 221);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(223, 221, 221);
  border-radius: 10px;
}
.team_grid:hover {
  box-shadow: 1px 1px 1.2rem rgb(167, 162, 162);
}

.team_grid img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  filter: saturate(0);
  transition: 1s;
}
.team_grid img:hover {
  filter: saturate(1);
}
.team_grids {
  display: grid;
  text-align: center;
  margin-top: 4rem;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 3rem;
}

@media screen and (max-width: 1000px) {
  .team_grids {
    grid-template-columns: 1fr 1fr;
    gap: 1.5rem;
  }
  .team_grid {
    width: 150px;
    height: 150px;
  }
  .team h1 {
    margin-left: 20px;
  }
  .team_grid img {
    filter: saturate(1);
  }
}
