@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;700;900&family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400&display=swap');
.Pay {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
}
.Pay_container {
  max-width: 1440px;
  padding: 0em 15rem;
  margin-top: 3rem;
}
.Pay_content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 2rem;
}

.Pay__po {
  position: absolute;
  top: 280%;
  right: 0;
}
.Pay_header {
  position: relative;
  margin-left: 5rem;
}
.Pay_text {
  width: 60%;
  font-size: 1.2rem;
  line-height: 32px;
  font-family: 'Open Sans';
  text-align: center;
}

.pay_img {
  background: linear-gradient(200deg, #c71e5b 30%, #ae1ec7);
  padding: 1rem 2rem;
  border-radius: 48px;
}

.pay_text {
  font-size: 3rem;
  margin: 0rem 2rem;
}
.Pay__img {
  width: 320px;
}
@media screen and (max-width: 1000px) {
  .Pay_content {
    display: block;
    padding-top: 2rem;
  }

  .Pay_header {
    left: 0;
    padding: 1.5rem 0;
  }
  .Pay__po {
    display: none;
  }
  .Pay_container {
    margin-top: 0rem;
  }

  .Pay_text {
    width: 100%;
    font-size: 15px;
  }
  .pay_text {
    font-size: 2rem;
    margin: 0;
    text-align: center;
  }
  .Pay_header {
    margin-left: 0rem;
  }
  .pay_img {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    padding: 1rem 2rem;
    width: 70%;

    margin: auto;
  }
  .Pay_container {
    padding: 0rem 2rem;
  }
  .Pay__img {
    width: 150%;
    object-fit: contain;
  }
}
