@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;700;900&family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400&display=swap');
.cards {
  width: 100%;
  height: 100%;
  margin-top: 1rem;
}

.cards h1 {
  text-align: center;
  color: #ffffff;
}

.card_h1 {
  background-color: black;
  width: 25%;
  margin: auto;
  padding: 0.1rem 0rem;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
}

.cards_text {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 2rem;
}
.cards_text h1 {
  color: #ffffff;
  padding-top: 4rem;
}

.cards__first {
  background: conic-gradient(
    from 180deg at 50% 50%,
    #ae1ec7 0deg,
    #c71e5b 114.38deg,
    #ae1ec7 360deg
  );
  height: 470px;
  border-radius: 48px;
}

ul li {
  width: 295px;
  color: #ffffff;
  font-family: 'Open Sans';
}
.all_cards {
  background-color: #000000;
  padding: 2rem 0rem;
  border-radius: 48px;
}
.cards .card_text {
  color: #000000;
}
.card_subtext {
  text-align: center;
}
.update {
  background-color: #000000;
  width: 15%;
  border-radius: 46px;
  margin: auto;
}
.update:hover {
  box-shadow: 1px 1px 1.2rem rgb(82, 81, 81);
}
.update_text {
  color: #ffffff;
  font-weight: 700;
  font-size: 1.5rem;
  padding: 0.8rem 1rem;
}
@media screen and (max-width: 1000px) {
  .cards_text {
    display: block;
  }
  .card_h1 {
    width: 80%;
  }
  /* .cards__first {
    padding: 0 2rem;
  } */

  .all_cards {
    padding: 1.5rem 2rem;
  }
  .cards .card_text {
    font-size: 20px;
  }
  .update {
    background-color: #000000;
    width: 70%;
    border-radius: 46px;
    margin: auto;
  }
  .update_text {
    font-size: 1rem;
  }
  /* .cards {
    padding: 0 1rem;
  } */
  ul li {
    width: 100%;
  }
}
