@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;700;900&family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400&display=swap');
.culture {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
}
.culture .culture_container {
  max-width: '1440px';
}
.culture_con {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4rem 3.5rem;
  margin-top: 5rem;
}
.back {
  background-image: url(../asset/background.png);
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: contain;
}

.culture_textline {
  text-decoration: none;
}
.culture_head {
  color: #ffffff;
  font-weight: 700;
}
.culture_page {
  width: 20rem;
  height: 100%;
  margin-top: 5rem;
  color: #ffffff;
  line-height: 26px;
  font-family: 'Open Sans';
}
.culture_img {
  width: 80%;
}
.btnclick {
  color: #ae1ec7;
  text-align: center;
}
.culture__icon {
  color: #ae1ec7;
  margin-left: 0.5rem;
  font-size: 30px;
}
.culture_text {
  display: flex;
  background-color: #ffffff;
  align-items: center;
  width: 250px;
  justify-content: center;
  border-radius: 8px;
  box-shadow: 1px 1px 1.2rem white;
}
.culture_text:hover {
  box-shadow: 1px 1px 1.2rem rgb(82, 81, 81);
}
.learn {
  padding-bottom: 2rem;
}

@media screen and (max-width: 1000px) {
  .culture_con {
    display: flex;
    flex-direction: column-reverse;
    margin: 0rem 1rem;
  }
  .back {
    object-fit: contain;
  }
  .learn {
    padding-bottom: 2rem;
  }
  .culture_img {
    object-fit: contain;
    width: 18rem;
    text-align: center;
  }
  .culture_content {
    padding: 0 0rem;
  }
}
