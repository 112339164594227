@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;700;900&family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400&display=swap');
.student {
  width: 100%;
  height: 100%;
}

.student-content {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3.5rem 10em;
  gap: 52px;
}

/* .student .iframe {
  width: 800px;
  height: 450px;
  border-radius: 48px;
  object-fit: cover;
} */
/* .sudy .allbtn {
  display: flex;
  justify-content: center;
  gap: 2rem;
  padding: 1rem 0rem;
} */
/* .sudy .btn {
  padding: 0.3rem 1rem;
  width: 15%;
  background-color: #ae1ec7;
  border-color: #ae1ec7;
  color: #ffffff;
  border-radius: 1rem;
  font-weight: 700;
} */
.student img {
  width: 600px;
  height: 25%;
  object-fit: contain;
}
iframe {
  width: 700px;
  height: 500px;
  border-radius: 48px;
}

.text--1 {
  font-weight: 700;
  color: #000;
  font-size: 48px;
  text-align: center;
}
.text-2 {
  width: 100%;
  text-align: center;
}
.student__text {
  font-size: 16px;
  font-weight: 700;
  color: #ae1ec7;
}
.student__text {
  color: #ffffff;
}

.stu_button {
  border-radius: 1px solid white;
  background-color: #ae1ec7;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 250px;
  padding: 0.5rem 2rem;
  margin: 3rem 3rem;
  box-shadow: 1px 1px 1.2rem rgb(104, 104, 104);
}
.stu_button:hover {
  box-shadow: 1px 1px 1.2rem rgb(82, 81, 81);
}
.stud__text {
  line-height: 32px;
  font-size: 1.2rem;
  color: #000;
  font-family: 'Open Sans';
  width: 400px;
}

@media screen and (max-width: 1000px) {
  .student-content {
    display: block;
    padding: 3rem 1rem;
  }

  /* .student .iframe {
    width: 340px;
    height: 200px;
    margin: auto;
    justify-content: center;
    display: flex;
    border-radius: 18px;
  } */

  .student img {
    width: 100%;
  }
  iframe {
    width: 300px;
    height: 300px;
    margin: auto;
    justify-content: center;
    display: flex;
    border-radius: 18px;
  }

  .text--1 {
    font-size: 1.5em;
  }
  .text-2 {
    font-size: 16px;
  }
  .student__text {
    font-size: 13px;
  }
  .student__icon {
    font-size: 5px;
  }

  .stu_button {
    width: 210px;
    margin: auto;
  }
  .stud__text {
    width: 100%;
    margin: 2rem 0rem;
  }
}
