@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;700;900&family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400&display=swap');
.follow {
  width: 100%;
  height: 100%;
}

.follow_container {
  margin: 2rem 3rem;
}

.follow_content {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.follow_text {
  background: linear-gradient(200deg, #c71e5b 30%, #ae1ec7);
  padding: 2rem 3rem;
  border-radius: 48px;
}
.follow h1 {
  font-size: 3rem;

  text-align: center;
  color: #ffffff;
}

.follow input {
  padding: 0.8rem 1rem;
  background-color: #f2f2f2;
  border: 1px solid #f2f2f2;
  display: flex;
  /* width: 280px; */
  justify-content: center;
  align-items: center;

  margin: auto;
}
.join {
  background-color: #000000;
  padding: 0.1px 0rem;

  border-radius: 48px;
  margin-top: 1rem;
}
.join p {
  color: #ffffff;
  text-align: center;
}

.follow_image {
  width: 300px;
  height: 200px;
  border-radius: 24px;
}
.follow_img {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.5rem;
  margin-top: 2rem;
}

@media screen and (max-width: 1000px) {
  .follow_content {
    display: flex;
    flex-direction: column-reverse;
  }
  .follow_image {
    width: 160px;
    height: 120px;
  }
  .follow_container {
    margin: 0rem 1rem;
  }

  .follow_img {
    column-gap: 0.3rem;
  }
  .follow h1 {
    font-size: 32px;
  }

  .follow input {
    font-size: 16px;
  }

  .follow_text {
    padding: 5rem 2rem;
    border-radius: 48px;
  }
}
