@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;700;900&family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400&display=swap');
.commu {
  width: 100%;
  height: 100%;
}
.commu_container {
  max-width: 1440px;
}
.commu_content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  direction: row;
  justify-content: space-between;
  margin-top: 100px;
}
.left {
  position: absolute;
  bottom: -50rem;
  left: 0;
  display: none;
}
.item {
  border: 2px solid #09ad0e;
  margin-left: 200px;
  border-radius: 8px;
}

.commulog {
  width: 100%;
}
.commu_text {
  font-size: 16px;
  position: relative;
  font-family: 'Open Sans';
  line-height: 26px;
}
.commu_first {
  width: 55%;
}
ul li {
  font-family: 'Open Sans';
  line-height: 26px;
}
.communicate_image {
  width: 90%;
}
.commu_content .all {
  max-width: 500px;
  padding: 10px;
}
.commit {
  max-width: 500px;
  margin-right: 200px;
  border: 2px solid #09ad0e;
  padding: 16px;
  border-radius: 10px;
}
.com {
  margin-top: 7rem;
}
.communicate_text {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  direction: row;
  justify-content: space-between;
  margin-top: 100px;
}
.right {
  position: absolute;
  left: 14rem;
  top: 470%;
  width: 50px;
  display: none;
}
.item2 {
  margin-left: 200px;
}

@media screen and (max-width: 1000px) {
  .commu_content .commu_img {
    display: none;
  }
  .commu_content .all {
    max-width: 100%;
    padding: 10px;
  }
  .allcommit {
    padding-left: 1rem;
  }
  .item {
    border: 2px solid #09ad0e;
    margin-left: 16px;
    border-radius: 8px;
  }
  .item2 {
    margin-left: 0px;
    padding-left: 1rem;
  }
  .communicate_image {
    display: none;
  }
  .commit_image {
    display: none;
  }
  .commit {
    margin-right: 16px;
  }
  .commu_content .commit_2 {
    width: 100%;
  }
  .right {
    display: block;
  }
  .left {
    display: block;
  }
}
