.slider-wrapper {
  position: relative;
  height: 250px;
  width: 90%;
  margin: auto;
  display: grid;
  place-items: center;
  overflow: hidden;
}

.slider-track {
  display: flex;
  animation: slidescroll 20s linear infinite;
  width: calc(250 * 18);
  transition: transform 1s;
}

@keyframes slidescroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 5));
  }
}
.slider-track:hover {
  animation-play-state: paused;
}

.slide {
  width: 250px;
  height: 200px;
  display: flex;
  align-items: center;
  padding: 15px;
  overflow: hidden;
  perspective: 100px;
  cursor: pointer;
  transition: transform 1s;
}
.slide img {
  max-width: 100%;
  min-height: 100%;
  object-fit: contain;
}

.slide img:hover {
  transform: translateZ(20px);
  transition: transform 1s;
}
/* shadow effect */
.slider-wrapper::before,
.slider-wrapper::after {
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  content: '';
  position: absolute;
  height: 100%;
  width: 15%;
  z-index: 2;
}

.slider-wrapper::before {
  left: 0;
  top: 0;
}
.slider-wrapper::after {
  right: -2px;
  top: 0;
  transform: rotateZ(180deg);
}

@media screen and (max-width: 960px) {
  .slide {
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    padding: 5px;
    overflow: hidden;
    perspective: 100px;
    cursor: pointer;
    transition: transform 1s;
  }
  @keyframes slidescroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(-250px * 4));
    }
  }
  .slider-wrapper {
    height: 100px;
  }
}
