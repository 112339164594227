@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;700;900&family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400&display=swap');
.budget {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
}
.budget .budget-container {
  max-width: 1440px;
  margin-top: 10rem;
  padding: 0em 15rem;
}
.budget .budget-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.budget span {
  font-size: 3rem;
}
.budget .budget_header {
  font-size: 2rem;
  text-align: center;
  position: relative;
  right: 100px;
}
.budget-container .budget_body {
  width: 60%;
  font-size: 1.2rem;
  line-height: 32px;
  text-align: center;
  font-family: 'Open Sans';
}
.budget__img {
  width: 320px;
  object-fit: contain;
  position: relative;
  top: 2rem;
}
.budget_img {
  background: linear-gradient(200deg, #c71e5b 30%, #ae1ec7);
  padding: 1rem 2rem;
  border-radius: 48px;
  z-index: 100;
}

@media screen and (max-width: 1000px) {
  .budget .budget-content {
    display: flex;
    flex-direction: column-reverse;
  }

  .budget .budget-container {
    margin-top: 4rem;
    padding: 0em 2rem;
  }
  .budget .budget_header {
    font-size: 2rem;
    width: 100%;
    right: 0rem;
  }
  .budget span {
    font-size: 2rem;
  }
  .budget_img {
    border-radius: 16px;
    width: 70%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3rem 3rem;
  }

  .budget__img {
    width: 220px;
  }

  .budget-container .budget_body {
    font-size: 16px;
    width: 100%;
  }
}
