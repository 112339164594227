.values_container {
  color: 'white';
  background-color: #ae1ec7;
  margin-top: 15rem;
}
.values_content {
  padding: 10px 0;
  color: #ffffff;
  text-align: center;
}
@media screen and (max-width: 1000px) {
  .values_container {
    margin-top: 5rem;
  }
}
