@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;700;900&family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400&display=swap');
.About {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
}
.About .about_container {
  max-width: '1440px';
  overflow: hidden;
  margin-top: 10rem;
  padding: 0em 10rem;
  background-color: #ffffff;
}
.about_content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8rem;
}
.group {
  width: 200px;
}
.group1 {
  width: 200px;
}
.Aboutus span {
  color: #09ad0e;
}
.about_text {
  text-align: center;
  width: 20rem;
  margin: auto;
  line-height: 26px;
  font-family: 'Open Sans';
}
.Aboutus h1 {
  width: 450px;
  text-align: center;
}

@media screen and (max-width: 1000px) {
  .group1 {
    display: none;
  }
  .group {
    display: none;
  }
  .Aboutus h1 {
    font-size: 1.5rem;
    width: 300px;
  }
}
